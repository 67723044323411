@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;800&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 */

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #343a40;
  color: #f8f9fa;
  font-family: "Poppins", sans-serif;
}

.body-bg-custom {
  background-color: #343a40;
}

.bg-transparent {
  background-color: transparent;
}

.bg-secondary-custom {
  background-color: #3c4146;
  color: rgba(255, 255, 255, 0.55) !important;
}

.bg-secondary-custom:hover {
  background-color: #55595e;
  color: rgba(255, 255, 255, 0.75) !important;
}

.bg-custom {
  background-color: #2d3741;
}

.margin-r-_5 {
  margin-right: 0.5rem;
}

.margin-l-_5 {
  margin-left: 0.5rem;
}

.badge-info-custom {
  border: 2px solid #0dcaf0;
  color: #0dcaf0;
  padding: 0.2rem 0.5rem;
}

.badge-warning-custom {
  border: 2px solid #ffc107;
  color: #ffc107;
  padding: 0.2rem 0.5rem;
}

.badge-success-custom {
  border: 2px solid #198754;
  color: #198754;
  padding: 0.2rem 0.4rem;
}

.badge-danger-custom {
  border: 2px solid #dc3545;
  color: #dc3545;
  padding: 0.2rem 0.5rem;
}

.nav-item-custom {
  color: rgba(255, 255, 255, 0.55);
  padding: 0.5rem 1rem;
}

.nav-item-custom:hover {
  background-color: #2b3035;
  display: block;
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-item-avatar-custom {
  color: rgba(255, 255, 255, 0.55);
  padding: 0.1rem 1rem;
  font-size: 0.9rem;
}

.profile-box-custom {
  width: 28rem;
  overflow-wrap: break-word;
}

@media (max-width: 767px) {
  .profile-box-custom {
    width: 100%;
  }
}

.btn-outline-violet-custom {
  border-color: #6c63ff;
  color: #6c63ff;
}

.btn-outline-violet-custom:hover {
  background-color: #6c63ff;
  color: white;
}

.btn-violet-custom {
  background-color: #6c63ff;
  color: white;
}

.btn-violet-custom:hover {
  background-color: #5953c9;
  color: white;
}

.btn-violet-custom:focus {
  background-color: #5953c9;
  color: white;
  outline: none;
  box-shadow: none;
}

.pride-banner {
  cursor: pointer;
  background: linear-gradient(
    to right,
    red,
    orange,
    yellow,
    green,
    royalblue,
    purple
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pride-banner:hover {
  background: linear-gradient(
    to left,
    red,
    orange,
    yellow,
    green,
    royalblue,
    purple
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pride-banner-text {
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
}

.pride-border {
  cursor: pointer;
  background: linear-gradient(#222, #222),
    linear-gradient(to left, red, orange, yellow, green, royalblue, purple);
  background-origin: padding-box, border-box;
  background-repeat: no-repeat; /* this is important */
  border: 2px solid transparent;
}

.pride-border:hover {
  padding: 0.5rem 0;
  background: linear-gradient(#222, #222),
    linear-gradient(to right, red, orange, yellow, green, royalblue, purple);
  background-origin: padding-box, border-box;
  background-repeat: no-repeat; /* this is important */
  border: 2px solid transparent;
}

.py-custom-_5 {
  padding: 0.5rem 0;
}

.no-underline {
  text-decoration: none !important;
  color: rgb(62, 158, 248);
}

.no-underline-muted {
  text-decoration: none !important;
  color: #6c757d !important;
}

.no-underline-muted:hover {
  color: rgb(184, 184, 184) !important;
}

.box {
  transition: opacity 0.3s linear;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
  /* display: block; */
}
.hidden {
  transition: opacity 0.3s linear;
  opacity: 0;
  -webkit-transform: translateX(-100);
          transform: translateX(-100);
  /* display: none; */
}

.box2 {
  transition: opacity 1s linear, height 1s step-start;
}

.hidden2 {
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  transition-timing-function: linear, step-end;
}

.admin-nav li:hover {
  /* background-color: #2c3035 !important;
  border-radius: 0.4rem; */
  /* border: 1px solid #7772d3;
  border-radius: 0.4rem; */
  background: linear-gradient(#212529, #212529),
    linear-gradient(45deg, rgb(95, 122, 240), rgb(223, 14, 112));
  background-origin: padding-box, border-box;
  background-repeat: no-repeat; /* this is important */
  border: 2px solid transparent;
  border-radius: 0.4rem;
}

.admin-nav-active {
  background: linear-gradient(#212529, #212529),
    linear-gradient(to right, rgb(32, 139, 238), rgb(223, 14, 112));
  background-origin: padding-box, border-box;
  background-repeat: no-repeat; /* this is important */
  border-bottom: 2px solid transparent;
}

.admin-nav-button {
  cursor: pointer;
  border: 2px solid rgba(122, 122, 122, 0.4);
  padding: 0 4px;
}

.admin-search-input {
  border: none;
  border-bottom: 1px solid #696a6b;
  background-color: transparent;
  color: #f8f9fa;
  align-items: stretch;
  width: 100%;
}

.admin-search-input:focus {
  border: none;
  border-bottom: 1px solid #696a6b;
  background-color: transparent;
  color: #f8f9fa;
  outline: none;
  box-shadow: none;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 */

